import type { Signer } from '@ethersproject/abstract-signer'
import type { Provider } from '@ethersproject/providers'
import approveAbi from '../config/abi/Approve.json'

import { Approve } from '../config/abi/types'

import { getContract } from './contractHelpers'


export const getApproveContract = (signer?: Signer | Provider) => {
  return getContract(approveAbi, "0x7d7a486508C0cc3CC31E25D54f8766E8Dd512F27", signer) as Approve
}